<!-- // FIXME @Deprecated -->

<template>
  <main class="page-overflow ma-4">


    <!-- page header -->
    <div class="page-header-3 mx-2">

      <!-- page header: filters -->
      <div class="page-header-filters">
        <!-- employee filter -->
        <v-menu max-height="200px" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-on="on" v-bind="attrs"
              name="none"
              placeholder="Filtra per dipendente"
              v-model="employeeToSearch"
              :loading="$apollo.queries.employees.loading"
              autocomplete="off"
              clearable
              dense
              @click:clear="employeeForFilterSelection(null)"
              ref="employeeFilter" />
          </template>
          <v-list>
            <v-list-item v-for="emp in employeeList" :key="emp.id" @click="employeeForFilterSelection(emp)">
              <span :style="emp.employmentEndDateStr < today ? 'color:red;font-style:italic' : ''">{{ emp.name }}</span>
            </v-list-item>

            <v-list-item v-if="employeeList.length === 0">
              {{ translations.noEmployeesFoundWThatName }}
            </v-list-item>
          </v-list>
          <div style="background: white;" class="d-flex justify-center" v-intersect="onIntersect" v-if="hasNextEmployees && !$apollo.queries.employees.loading">
            <v-progress-circular indeterminate color="main"></v-progress-circular>
          </div>
        </v-menu>

        <!-- status filters -->
        <v-select :items="statusFilter" item-text="label" item-value="value" v-model="filterValues.status" :placeholder="translations.statusFilter" clearable dense ref="statusFilter"></v-select>

        <!-- dateFrom filter -->
        <v-menu v-model="showDateFromPicker" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateFromLabel" :placeholder="translations.dateFrom"
                          v-on="on" v-bind="attrs" readonly clearable dense
                          @click:clear="() => onDateFromChange(null)" />
          </template>
          <v-date-picker v-model="dateFromPicker" :max="dateToPicker" @change="onDateFromChange" locale="it" />
        </v-menu>

        <!-- dateTo filter -->
        <v-menu v-model="showDateToPicker" transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateToLabel" :placeholder="translations.toDate"
                          v-on="on" v-bind="attrs" readonly clearable dense
                          @click:clear="() => onDateToChange(null)" />
          </template>
          <v-date-picker v-model="dateToPicker" :min="dateFromPicker" @change="onDateToChange" locale="it" />
        </v-menu>
      </div>

      <!-- page header: title -->
      <div class="all-center-column">
        <h3 class="main--text">{{ translations.employeeRequest }}</h3>

        <h6 class="main--text" style="cursor:pointer" @click="$router.push('/back-office-vehicle')">
          {{ translations.goTo }} {{ translations.vehicleRequest }}
          <v-icon :size="10">mdi-arrow-right</v-icon>
        </h6>
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <div class="all-center" style="width: 20%">
          <!-- Print report button-->
          <v-btn icon dark color="main" @click="printActivityRequestReport">
            <v-icon :size="28">mdi-printer</v-icon>
          </v-btn>
        </div>

        <div class="all-center" style="width: 60%">
          <!-- New Request Dialog -->
          <back-office-activity-request-dialog :show="showRequestDialog"
                                               :loading="loadingNewRequest"
                                               @close="showRequestDialog = false"
                                               @send-request="sendRequestHandler">
            <template slot="new-request">
              <v-btn color="main" class="white--text" @click="showRequestDialog = true" height="32px" depressed>
                <v-icon class="mr-1">{{ icons.mdiPlus }}</v-icon>
                {{ translations.request }}
              </v-btn>
            </template>
          </back-office-activity-request-dialog>
        </div>
      </div>
    </div>


    <!-- main content -->
    <v-card class="ma-1">
      <v-data-table :headers="headers" :items="requests" :items-per-page="10"
                    class="page-table" fixed-header hide-default-footer :update:options="{ page: page, itemsPerPage: pageSize }">

        <template v-slot:item.name="{ item }">
          <span :style="item.employmentEndDateStr < today ? 'color:red;font-style:italic' : ''">{{ item.name }}</span>
        </template>

        <template v-slot:item.dayPart="{ item }">
          <span>{{ item.dayPart ? dayPartOptions[item.dayPart] : '-' }}</span>
        </template>

        <!-- Action Menu will change to dialog for better ux -->
        <template v-slot:item.status="{ item }">
          <v-row class="ma-0" align="center">
            <v-col class="pa-0" cols="4">
              <v-chip small label :color="getColor(item.status, 'bg')" class="mr-2">
                <span :style="[{ color: getColor(item.status, 'text') }]">{{ item.status }}</span>
              </v-chip>
            </v-col>

            <v-col class="pa-0" cols="auto">
              <v-menu v-if="item.status === 'In Attesa'" v-model="item.actionMenu" :close-on-content-click="false" :nudge-width="350" offset-x left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on" class="mr-2">{{ icons.mdiSquareEditOutline }}</v-icon>
                </template>

                <v-card class="pa-3">
                  <h3>{{ translations.request }}</h3>
                  <div class=" d-flex mt-2 align-center">
                    <v-icon color="main">{{ icons.mdiAccountBox }}</v-icon>
                    <b>{{ item.name }}</b>
                  </div>

                  <div class="mt-2">
                    <span><b>{{ translations.status }}:</b> {{ item.status }}</span>
                  </div>

                  <p v-if="!item.showTextArea" class="mt-2 mb-1">{{ translations.acceptOrDeclineRequest }}:</p>

                  <div v-if="!item.showTextArea" class="mt-2 d-flex justify-center">
                    <v-btn @click="handleShowTextArea(item)" small color="error" class="mr-2" text depressed> {{ translations.deny }}</v-btn>

                    <v-btn @click="actionHandler(item, 'APPROVED')" small color="main" class="white--text" depressed> {{ translations.approve }}</v-btn>
                  </div>

                  <div v-if="item.showTextArea" class="mt-4">
                    <span class="mb-2">{{ translations.optionalNoteForRequest }}:</span>
                    <v-textarea v-model="item.note" filled flat dense hide-details></v-textarea>

                    <div class="mt-4" style="text-align: right">
                      <v-btn @click="actionHandler(item, 'REJECTED')" small class="white--text" color="main">{{ translations.confirm }}</v-btn>
                    </div>
                  </div>
                </v-card>
              </v-menu>

              <v-menu v-else v-model="item.actionMenu" :close-on-content-click="false" :nudge-width="350" offset-x left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small v-bind="attrs" v-on="on" class="mr-2">{{ icons.mdiEye }}</v-icon>
                </template>

                <v-card class="pa-3">
                  <h3>{{ translations.request }}:</h3>
                  <div class=" d-flex mt-2 align-center">
                    <v-icon color="main">{{ icons.mdiAccountBox }}</v-icon>
                    <b>{{ item.name }}</b>
                  </div>

                  <div class="mt-2">
                    <span><b>{{ translations.status }}:</b> {{ item.status }}</span>
                  </div>

                  <div v-if="item.status === 'Negato' && item.note">
                    <b>{{ translations.deny }}</b>
                    <p>{{ item.note }}</p>
                  </div>

                  <div class="mt-4">
                    <p>
                      {{ translations.youCanChangeStatusFrom }}
                      <b>{{ item.status }}</b> a <b>{{ item.status === 'Negato' ? 'Approvato' : 'Negato' }}</b>!
                    </p>
                  </div>

                  <div v-if="!item.showTextArea" class="mt-2 d-flex justify-end">
                    <v-btn v-if="item.status === 'Approvato'" @click="handleShowTextArea(item)" small color="error" class="whtie--text" depressed> {{ translations.deny }}</v-btn>

                    <v-btn v-else @click="actionHandler(item, 'APPROVED')" small color="main" class="white--text" depressed> {{ translations.approve }}</v-btn>
                  </div>

                  <div v-if="item.showTextArea" class="mt-4">
                    <span class="mb-2">{{ translations.optionalNoteForRequest }}</span>
                    <v-textarea v-model="item.note" filled flat dense hide-details></v-textarea>

                    <div class="mt-4" style="text-align: right">
                      <v-btn @click="actionHandler(item, 'REJECTED')" small class="white--text" color="main">{{ translations.confirm }}</v-btn>
                    </div>
                  </div>
                </v-card>
              </v-menu>
            </v-col>

            <v-col class="pa-0" cols="auto">
              <v-progress-circular v-if="item.loading" :width="3" :size="20" color="red" indeterminate></v-progress-circular>
              <template v-else>
                <v-icon @click="deleteRequestHandler(item)" small color="error lighten-1" class="cursor-pointer">mdi-delete</v-icon>
              </template>
            </v-col>
          </v-row>
        </template>

        <!-- footer -->
        <template v-slot:footer>
          <div class="d-flex justify-end align-center pa-2">
            <span class="mr-2">{{ page === 1 ? initialNumber : (page - 1) * 10 + 1 }} - {{ (page - 1) * 10 + requests.length }} di {{ totalRequests }}</span>

            <v-btn @click="onPreviousTableChange()" class="mr-2" icon :disabled="page === 1">
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            <v-btn @click="onNextTableChange" class="" icon :disabled="page === totalPages">
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>


    <message-dialog :show="showErrorMsgDialog">
      <template v-slot:card-image>
        <v-row class="ma-0" justify="center">
          <warning-svg :brandColor="mainColor" width="200px"></warning-svg>
        </v-row>
      </template>

      <template v-slot:title>
        <p class="mt-4">{{ translations.overlappingDatesError }}</p>
      </template>

      <template v-slot:card-action>
        <div class="d-flex justify-end">
          <v-btn @click="showErrorMsgDialog = false" text small>{{ translations.close }}</v-btn>
        </div>
      </template>
    </message-dialog>
  </main>
</template>


<script>
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'

import { mdiAccountBox, mdiChevronLeft, mdiChevronRight, mdiEye, mdiPlus, mdiSquareEditOutline } from '@mdi/js'
import { translations } from '@/utils/common'

//Queries and Mutations
import activityRequestQuery from '@/graphql/ActivityRequest.gql'
import employeesQuery from '@/graphql/Employees.gql'
import handleActivityRequestMutation from '@/graphql/HandleActivityRequest.gql'
import saveActivityRequestMutation from '@/graphql/SaveActivityRequest.gql'
import deleteActivityRequestMutation from '@/graphql/DeleteActivityRequest.gql'

//Components
import BackOfficeActivityRequestDialog from '@/views/BackOffice/BackOfficeActivityRequestDialog.vue'
import reportsApi from '@/utils/api/reportsApi'

const MessageDialog = () => import('@/components/MessageDialog.vue')
const WarningSvg = () => import('@/assets/WarningSvg.vue')

export default {
  name: 'BackOfficeActivityRequest',

  components: {
    BackOfficeActivityRequestDialog,
    MessageDialog,
    WarningSvg
  },

  apollo: {
    activityRequestsOLD: {
      query: activityRequestQuery,
      variables() {
        return {
          page: this.page,
          pageSize: this.pageSize,
          employeeId: this.filterValues.employee,
          dateFromStr: this.filterValues.dateFrom ? this.formatDate(this.filterValues.dateFrom) : null,
          dateToStr: this.filterValues.dateTo ? this.formatDate(this.filterValues.dateTo) : null,
          status: this.filterValues.status,
          sort: 'dateFrom',
          order: 'desc'
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const result = data?.activityRequestsOLD

        const statusList = {
          approved: translations.approved,
          pending: translations.pending,
          rejected: translations.rejected
        }

        this.totalRequests = result.totalElements
        this.totalPages = result.totalPages
        this.hasNext = result.hasNext

        this.requests = result.content.map(request => {
          return {
            employeeId: request.employee.id,
            name: request.employee.name,
            employmentEndDateStr: request.employee.employmentEndDateStr,
            type: request.employeeStatus.description,
            dateFrom: this.momentDate(request.dateFromStr, 'DD/MM/YYYY'),
            dateTo: this.momentDate(request.dateToStr, 'DD/MM/YYYY'),
            status: statusList[request.status.toLowerCase()],
            id: request.id,
            note: request.note,
            dayPart: request.dayPart,
            actionMenu: false,
            showTextArea: false,
            loading: false
          }
        })
      }
    },

    employees: {
      query: employeesQuery,
      variables() {
        return {
          page: 1,
          pageSize: 10,
          sort: 'name',
          order: 'asc',
          name: this.employeeToSearch
        }
      },
      fetchPolicy: 'network-only',
      result({ data }) {
        const result = data?.employees

        this.employeeList = result.content
        this.hasNextEmployees = result.hasNext
      }
    }
  },

  watch: {
    employeeToSearch() {
      this.employeeQueryVars.page = 1
    }
  },

  data() {
    return {
      translations: translations,

      page: 1,
      pageSize: 10,
      employeeQueryVars: {
        page: 1,
        pageSize: 10,
        sort: 'name',
        order: 'asc'
      },
      hasNextEmployees: false,
      totalRequests: null,
      totalPages: null,
      filterValues: {
        employee: null,
        status: null,
        dateFrom: null,
        dateTo: null
      },
      requests: [],
      showDateFromPicker: false,
      dateFromPicker: null,
      dateFromLabel: null,
      showDateToPicker: false,
      dateToPicker: null,
      dateToLabel: null,
      employeeList: [],
      employeeToSearch: '',
      hasNext: null,
      showRequestDialog: false,
      loadingNewRequest: false,
      showErrorMsgDialog: false
    }
  },

  created() {
    this.picker = this.getSimpleDay().substr(0, 10)
  },

  mixins: [MomentMixin],

  methods: {
    onDateFromChange(date) {
      this.filterValues.dateFrom = date ? this.simpleFormat(date) : null
      this.page = 1
      this.dateFromLabel = date ? this.momentDate(date, 'DD/MM/YYYY') : null
      this.dateFromPicker = date ? date : null
    },

    onDateToChange(date) {
      this.filterValues.dateTo = date ? this.simpleFormat(date) : null
      this.page = 1
      this.dateToLabel = date ? this.momentDate(date, 'DD/MM/YYYY') : null
      this.dateToPicker = date ? date : null
    },

    employeeForFilterSelection(employee) {
      this.employeeToSearch = employee ? employee.name : ''
      this.filterValues.employee = employee ? employee.id : null
      this.page = 1
      if (!employee) {
        this.$refs.employeeFilter.blur()
      }
    },

    onNextTableChange() {
      if (!this.hasNext) return
      this.page++
    },

    onPreviousTableChange() {
      if (this.page === 1) return
      this.page--
    },

    getColor(status, type) {
      const colors = {
        approvato: {
          bg: '#E7F5E9',
          text: '#2A6A2D'
        },
        inattesa: {
          bg: '#FFFCDE',
          text: '#262626'
        },
        negato: {
          bg: '#FFEAEC',
          text: '#D60011'
        }
      }
      const statusLowerCase = status.toLowerCase().replace(' ', '')

      return colors[statusLowerCase][type]
    },

    async actionHandler(item, action) {
      const index = this.requests.findIndex(req => req.id === item.id)
      try {
        await this.$apollo
          .mutate({
            mutation: handleActivityRequestMutation,
            variables: {
              note: item.note,
              activityRequestId: item.id,
              employeeId: item.employeeId,
              status: action
            }
          })
          .then(() => {
            this.$root.vtoast.show({ message: translations.updateSuccess })
            this.$apollo.queries.activityRequestsOLD.refetch()
          })
          .catch(error => {
            this.handleError(error)
          })

        this.requests[index].actionMenu = false
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    handleShowTextArea(value) {
      const index = this.requests.findIndex(req => req.id === value.id)
      this.requests[index].showTextArea = true
    },

    async sendRequestHandler(activityRequestObject) {
      this.loadingNewRequest = true
      try {
        await this.$apollo.mutate({
            mutation: saveActivityRequestMutation,
            variables: {
              activityRequestObject
            }
          })
          .then(() => {
            this.$root.vtoast.show({ message: translations.activityHasBeenSaved })
            this.$apollo.queries.activityRequestsOLD.refetch()
          })
          .catch(error => {
            this.handleError(error)
          })
      } catch (e) {
        this.showErrorMsgDialog = true
      }
      this.loadingNewRequest = false
    },

    async deleteRequestHandler(item) {
      try {
        item.loading = true
        await this.$apollo
          .mutate({
            mutation: deleteActivityRequestMutation,
            variables: {
              activityRequestId: item.id,
              employeeId: item.employeeId
            }
          })
          .then(() => {
            this.$root.vtoast.show({ message: translations.deletedSuccess })
            this.$apollo.queries.activityRequestsOLD.refetch()
          })
          .catch(error => {
            this.handleError(error)
          })

      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        await this.fetchMoreEmployees()
      }
    },

    async fetchMoreEmployees() {
      this.employeeQueryVars.page++

      const res = await this.$apollo.query({
        query: employeesQuery,
        variables: { ...this.employeeQueryVars, name: this.employeeToSearch },
        fetchPolicy: 'network-only'
      })
      this.hasNextEmployees = res.data.employees.hasNext
      this.employeeList.push(...res.data.employees.content)
    },

    printActivityRequestReport() {
      reportsApi.activityRequestReport(
        this.filterValues.dateFrom ? this.formatDate(this.filterValues.dateFrom) : null,
        this.filterValues.dateTo ? this.formatDate(this.filterValues.dateTo) : null,
        this.filterValues.employee,
        this.filterValues.status
      )
    }
  },

  computed: {
    icons: () => ({
      mdiPlus,
      mdiSquareEditOutline,
      mdiAccountBox,
      mdiChevronRight,
      mdiChevronLeft,
      mdiEye
    }),

    statusList() {
      return {
        APPROVED: translations.approved,
        PENDING: translations.pending,
        REJECTED: translations.rejected
      }
    },

    headers() {
      return [
        {
          text: translations.employee,
          align: 'start',
          sortable: false,
          value: 'name'
        },
        {
          text: translations.requestType,
          align: 'start',
          sortable: false,
          value: 'type'
        },
        {
          text: translations.date + ' (' + translations.from + ')',
          align: 'start',
          sortable: false,
          value: 'dateFrom'
        },
        {
          text: translations.date + ' (' + translations.to + ')',
          align: 'start',
          sortable: false,
          value: 'dateTo'
        },
        {
          text: translations.dayPart,
          align: 'start',
          sortable: false,
          value: 'dayPart'
        },
        {
          text: translations.status,
          align: 'start',
          sortable: false,
          value: 'status'
        }
      ]
    },

    statusFilter() {
      return [
        { label: translations.approved, value: 'APPROVED' },
        { label: translations.pending, value: 'PENDING' },
        { label: translations.rejected, value: 'REJECTED' }
      ]
    },

    initialNumber() {
      if (this.requests.length === 0) return 0

      return 1
    },

    dayPartOptions() {
      return {
        1: 'Mattina',
        2: 'Pomeriggio'
      }
    },

    mainColor() {
      return this.$vuetify.theme.themes.light.main
    },

    today() {
      return this.getSimpleDay().slice(0, 10)
    }
  }
}
</script>

<style scoped>
.v-alert {
  margin-bottom: 0 !important;
}
</style>
